var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMusicStudent || _vm.juries.length > 0)?_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Music Juries")]),_vm._l((_vm.juries),function(ref){
var _id = ref._id;
var start = ref.start;
var instrument = ref.instrument;
var pieces = ref.pieces;
return _c('v-list-item',{key:_id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Jury reserved for "+_vm._s(instrument)+" on "+_vm._s(_vm.stringFormatDate(start)))]),_vm._l((pieces),function(ref,index){
var name = ref.name;
var composer = ref.composer;
return _c('v-list-item-subtitle',{key:_id + '-' + index},[_c('v-chip',[_vm._v(_vm._s(name)+" - "+_vm._s(composer))])],1)})],2)],1)})],2),_c('v-card-actions',[_c('v-btn',{attrs:{"to":"/student/music","text":""}},[_vm._v("Go to Music Jury system")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }