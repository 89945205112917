<template>
  <v-card v-if="isMusicStudent || juries.length > 0">
    <v-list>
      <v-subheader>Music Juries</v-subheader>
      <v-list-item v-for="{ _id, start, instrument, pieces } in juries" :key="_id">
        <v-list-item-content>
          <v-list-item-title>Jury reserved for {{ instrument }} on {{ stringFormatDate(start) }}</v-list-item-title>
          <v-list-item-subtitle v-for="({ name, composer }, index) in pieces" :key="_id + '-' + index">
            <v-chip>{{ name }} - {{ composer }}</v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn to="/student/music" text>Go to Music Jury system</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isMusicStudent = ref(false)
    const juries = ref([])

    onMounted(async () => {
      // Get the current term
      let term = ''
      const { data: terms } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', start: { $lte: new Date() }, end: { $gte: new Date() } } })
      if (terms.length > 0) {
        term = terms[0].term
      }
      // Check to see if the student already has a music jury slot for the current term
      // In testing, some of the users do not have directoryId's; this is addressed now as part of the login process, but we are putting an invalid value in so nothing will return if they do not have a directoryId
      const { data: juryData } = await root.$feathers.service('student/music/jury-slot').find({ query: { term, student: user.value.directoryId || '000000000000000000000000' } })
      if (juryData.length > 0) {
        juries.value = juryData
      } else {
        // If they do not already have a reserved jury slot, check to see if they are enrolled in any music lesson courses (MUS*01A/B-*08A/B)
        const { data } = await root.$feathers.service('calendar/classes').find({ query: { term, title: { $regex: '^MUS[1-4]0[1-9]' }, 'students.pidm': user.value.pidm } })
        if (data.length > 0) isMusicStudent.value = true
      }
    })

    return {
      user,
      isMusicStudent,
      juries,
      stringFormatDate
    }
  }
}
</script>
